@charset "UTF-8";
.main-keyvisual {
  position: relative;
  height: 610px;
  margin-bottom: 30px;
  overflow: hidden;
}

.main-keyvisual .main-kv-swiper {
  height: 100%;
}

.main-keyvisual .main-kv-swiper:not(.swiper-initialized) .swiper-units {
  display: none;
}

.main-keyvisual .main-kv-swiper .swiper-slide a {
  display: block;
  text-align: center;
  overflow: hidden;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.main-keyvisual .main-kv-swiper .swiper-slide a img {
  max-width: none;
}

.main-keyvisual .main-kv-swiper .swiper-units {
  position: absolute;
  bottom: 25px;
  left: calc(50% + 705px);
  z-index: 1;
}

.main-keyvisual .main-kv-swiper .swiper-units .inner {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.main-keyvisual .main-kv-swiper .swiper-units .inner .auto-stop.active {
  display: none;
}

.main-keyvisual .main-kv-swiper .swiper-units .inner .auto-stop.active + .auto-play {
  display: inline-block;
}

.main-keyvisual .main-kv-swiper .swiper-units .inner .auto-play {
  display: none;
}

.main-keyvisual .main-kv-swiper .swiper-units .swiper-pagination {
  position: relative;
  bottom: 0;
  width: auto;
  margin-left: 10px;
  padding: 0 20px;
  display: inline-flex;
  font-size: 0;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.3);
}

.main-keyvisual .main-kv-swiper .swiper-units .swiper-pagination > span {
  position: relative;
  font-weight: normal;
  font-size: 14px;
  line-height: 35px;
  color: #fff;
  opacity: 0.5;
}

.main-keyvisual .main-kv-swiper .swiper-units .swiper-pagination > span.swiper-pagination-current {
  font-weight: 700;
  opacity: 1;
}

.main-keyvisual .main-kv-swiper .swiper-units .swiper-pagination > span.swiper-pagination-total::before {
  content: '/';
  padding: 0 5px;
}

.brand-links {
  padding: 0 75px;
  font-size: 0;
}

.brand-links > a {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 90px;
  margin: 20px 40px 0 0;
}

.brand-links > a img {
  display: block;
  width: 90px;
  height: 90px;
  transition: 0.3s;
}

.brand-links > a .thumb {
  display: block;
  border-radius: 50%;
  overflow: hidden;
}

.brand-links > a span.txt {
  display: block;
  margin-top: 11px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #333;
  transition: 0.3s;
}

.brand-links > a:nth-child(-n + 10) {
  margin-top: 0;
}

.brand-links > a:nth-child(10n) {
  margin-right: 0;
}

.brand-links > a:hover .txt {
  font-weight: 700;
}

/* 오늘이 지나면 생각날 가격 */
.main-time-event {
  height: 690px;
  padding: 100px 0 0 0;
  background: #e5e5e5 url("../images/main/bg_main_timeevent.jpg") no-repeat center top;
}

.main-time-event .title-t {
  padding-bottom: 8px;
}

.main-time-event .time-cont {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}

.main-time-event .time-cont .btn.btt2 {
  margin-left: auto;
}

/* 오늘이 지나면 생각날 가격 */
/* 오뚜기 MD가 엄선한 갓상품이에요 */
.md-recommend .swiper-slide {
  height: 600px;
  position: relative;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
}

.md-recommend .swiper-slide > img {
  max-width: none;
}

.md-recommend .swiper-slide .in-cont {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  align-items: stretch;
  width: 1410px;
  height: 100%;
  text-align: left;
  transform: translateX(-50%);
}

.md-recommend .swiper-slide .in-cont .l-side {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.md-recommend .swiper-slide .in-cont .l-side .title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

.md-recommend .swiper-slide .in-cont .l-side .text {
  padding-top: 16px;
  font-size: 18px;
  line-height: 28px;
}

.md-recommend .swiper-slide .in-cont .r-side {
  display: flex;
  align-self: flex-end;
  width: 705px;
  padding: 0 0 100px 20px;
}

/* md 상품  */
.md-recommend-prd {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px 90px 10px 10px;
  border-radius: 8px;
  background-color: #fff;
}

.md-recommend-prd .thumbs {
  flex: none;
  width: 100px;
}

.md-recommend-prd .desc {
  padding: 0 10px;
}

.md-recommend-prd .desc .name {
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: initial;
}

.md-recommend-prd .desc .price {
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.md-recommend-prd .desc .price .amount {
  display: flex;
  align-items: center;
}

.md-recommend-prd .desc .price .per {
  margin-right: 3px;
  color: #d3233a;
}

.md-recommend-prd .desc .price .won {
  margin-right: 6px;
  font-size: 16px;
}

.md-recommend-prd .desc .price del {
  font-weight: 400;
  font-size: 14px;
  color: #ccc;
}

.md-recommend-prd .desc .prd-item-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.md-recommend-prd + .md-recommend-prd {
  margin-left: 10px;
}

/* md 상품  */
/* 오뚜기 MD가 엄선한 갓상품이에요 */
/* 마일리지로 구매해보세요 */
.main-mileage {
  height: 600px;
  padding: 100px 0 0 0;
  background-color: #f9f2ec;
  background-position: center top;
  background-repeat: no-repeat;
}

/* 마일리지로 구매해보세요 */
/* 이렇게 먹으면 더 맛있어요 */
.cook-item .swiper-slide {
  display: inline-block;
  width: 960px;
}

.cook-item .swiper-slide .inner {
  width: 930px;
  margin: 0 auto;
}

.cook-item .swiper-slide .inner .thumb {
  overflow: hidden;
  position: relative;
  border-radius: 4px;
}

.cook-item .swiper-slide .inner .thumb .kv {
  border-radius: 8px;
}

.cook-item .swiper-slide .inner .thumb .item-thumb {
  position: absolute;
  right: 0;
  bottom: -105px;
}

.cook-item .swiper-slide .inner .cont {
  padding: 40px 230px 0 0;
  visibility: hidden;
}

.cook-item .swiper-slide .inner .cont .text {
  padding-top: 16px;
  font-size: 18px;
  line-height: 30px;
}

.cook-item .swiper-slide .inner .related-wrap {
  margin-top: 40px;
  visibility: hidden;
}

.cook-item .swiper-slide .inner .related-content {
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px 0 rgba(136, 136, 136, 0.1);
}

.cook-item .swiper-slide .inner .related-content .swiper-button-next.swiper-button-disabled,
.cook-item .swiper-slide .inner .related-content .swiper-button-prev.swiper-button-disabled {
  display: none;
}

.cook-item .swiper-slide .inner .related-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px 15px 40px;
  color: #fff;
  border-radius: 0 0 8px 8px;
  background-color: #111;
}

.cook-item .swiper-slide .inner .related-content-bottom > .tit {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.cook-item .swiper-slide .inner .related-content-bottom > .r-side {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.cook-item .swiper-slide .inner .related-content-bottom > .r-side .price {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

.cook-item .swiper-slide .inner .related-content-bottom > .r-side .price .won {
  font-size: 20px;
}

.cook-item .swiper-slide .inner .related-content-bottom > .r-side .btns {
  margin-left: 30px;
  font-size: 0;
}

.cook-item .swiper-slide .inner .related-content-bottom > .r-side .btns .btn + .btn {
  margin-left: 10px;
}

.cook-item .swiper-slide.swiper-slide-active .cont,
.cook-item .swiper-slide.swiper-slide-active .related-wrap {
  visibility: visible;
}

.related-swiper {
  padding: 30px 29px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #f8f8f8;
  border-bottom: none;
}

.related-swiper:not(.swiper-initialized) {
  font-size: 0;
}

.related-swiper:not(.swiper-initialized) .swiper-slide {
  display: inline-block;
  vertical-align: top;
  width: 270px;
}

.related-swiper:not(.swiper-initialized) .swiper-slide + .swiper-slide {
  margin-left: 30px;
}

.related-swiper .swiper-slide {
  overflow: hidden;
}

/* 관련상품 */
.related-prd {
  position: relative;
  display: flex;
  align-items: center;
}

.related-prd .chkbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 85%;
  height: 100%;
}

.related-prd .chkbox label::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.related-prd .thumb {
  flex: none;
  width: 100px;
}

.related-prd .desc {
  width: 48%;
  padding: 0 10px;
}

.related-prd .desc .name {
  font-size: 16px;
  line-height: 20px;
  color: #888;
}

.related-prd .desc .price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.related-prd .desc .price .amount {
  display: flex;
  align-items: center;
}

.related-prd .desc .price .per {
  margin-right: 3px;
  color: #d3233a;
}

.related-prd .desc .price .won {
  margin-right: 6px;
  font-size: 16px;
}

.related-prd .desc .price del {
  font-weight: 400;
  font-size: 14px;
  color: #ccc;
}

.related-prd .desc .prd-item-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.related-prd.soldout-ty1 .thumb a::before {
  content: '일시품절';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background: rgba(255, 255, 255, 0.7);
}

/* 관련상품 */
/* 이렇게 먹으면 더 맛있어요 */
